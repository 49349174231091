import { useState } from 'react';
import organizationLogo from 'assets/images/bigLanguageLogo.png';
import menuIcon from 'assets/images/menu.svg';
import accountSettings from 'assets/images/accountSettings.svg';
import navbarIcon from 'assets/images/menuIcon.svg';
import { Sidebar } from 'primereact/sidebar';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import AuthStore, { UserInfoModal } from 'core/store/authStore/authStore';
import { PATHS } from 'constants/routes.constant';
import { getAvatarName } from 'core/utils/utils';
import { Tooltip } from 'primereact/tooltip';
import FeedbackPopupModal from 'components/accordion/feedbackPopupModal.tsx/feedbackPopupModal';
import { Permissions } from 'constants/permissions';
import { generateHeaderMenu } from './headerMenu.constant';

export interface MenuItem {
    menu: string;
    id: number;
    permission?: string[];
    path: string;
    submenu?: SubmenuItem[];
    isValid?: boolean;
}

export type TransformedMenuItem = {
    id: number;
    menu: string;
    path: string;
    isSubmenu?: boolean;
};
interface SubmenuItem {
    title: string;
    id: number;
    permission: string[];
    path: string;
}

function HeaderMenu() {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const navigate = useNavigate();
    const [headerMenuData] = useState<MenuItem[]>(generateHeaderMenu());
    const [headerMenuItemsLength, setHeaderMenuItemsLength] = useState(false);
    const [isMenuDropdownOpen, setMenuDropdownOpen] = useState(false);
    const [feedbackPopup, setFeedbackPopup] = useState(false);
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
    const [visible, setVisible] = useState<boolean>(false);
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split('/');

    const [userInfo] = useState<UserInfoModal>(AuthStore.getUserInfo());

    const userMenu: MenuItem[] = headerMenuData.filter((menu) => {
        if (menu.submenu) {
            return menu.submenu.find((sm) => {
                const hasSubMenuPermission = sm.permission.find((sp) => {
                    return userInfo.userInfo.permissions.find((p) => sp === p);
                });
                return hasSubMenuPermission ? sm : false;
            });
        }
        return menu.permission?.find((mp) => {
            return userInfo.userInfo.permissions.find((p) => mp === p);
        });
    });

    const isActive = (path: string) => splitLocation[1].toLowerCase() === path.toLowerCase();

    const isSubmenuActive = (submenu: SubmenuItem[]) => {
        return submenu.some((submenuItem: SubmenuItem) => isActive(submenuItem.path));
    };
    const toggleDropdown = (status: boolean) => {
        setMenuDropdownOpen(status);
    };
    const toggleProfileDropdown = (status: boolean) => {
        setIsProfileDropdownOpen(status);
    };

    const handleAccountSettings = () => {
        navigate(PATHS.ACCOUNT_SETTINGS, {
            state: { from: pathname === PATHS.ACCOUNT_SETTINGS ? PATHS.ORDER_QUOTE : pathname }
        });
    };
    const handlePvSetting = () => {
        navigate(PATHS.PV_SETTINGS);
    };
    const handleLogout = () => {
        localStorage.clear();
        AuthStore.logout();
        instance.logoutRedirect();
    };

    const [menuIconHighlightIcon, SetMenuIcoHighlightIcon] = useState(false);

    const menuIconStyle = {
        filter: 'brightness(0) saturate(100%) invert(18%) sepia(87%) saturate(7500%) hue-rotate(355deg) brightness(96%) contrast(116%)'
    };

    const transformMenu = (menuItems: MenuItem[]): TransformedMenuItem[] => {
        const result: TransformedMenuItem[] = [];

        // Process each menu item and collect submenu entries
        menuItems.forEach((item) => {
            if (item.submenu) {
                // Add the submenu items to the map
                item.submenu.forEach((sub) => {
                    result.push({
                        id: sub.id,
                        menu: `${t(item.menu)} - ${t(sub.title)}`,
                        path: sub.path,
                        isSubmenu: true
                    });
                });
            } else {
                // If the item does not have a submenu, add it to the result list
                result.push({
                    id: item.id,
                    menu: t(item.menu),
                    path: item.path
                });
            }
        });

        // Exclude top-level menu items that have submenus
        return result.filter((menuItem) => {
            return !menuItems.some((item) => item.submenu && item.id === menuItem.id);
        });
    };

    const isUATEnvIndicate: boolean =
        (process.env.REACT_APP_ENABLE_SANDBOX_LABEL ?? 'false').toLowerCase() === 'true';

    return (
        <div data-testid="HeaderMenu">
            <div className="bg-white w-full border-t-[0.25rem] border-secondary shadow shadow-neutral-100 h-[4.3rem] flex items-center px-3 relative z-10">
                <div className="h-full py-2 flex items-center">
                    <div className="lg:hidden mr-2">
                        <img
                            src={navbarIcon}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    setVisible(true);
                                }
                            }}
                            onClick={() => setVisible(true)}
                            data-testid="navbar-icon"
                        />
                    </div>
                    <img
                        src={organizationLogo}
                        className="h-full"
                        alt="Organization logo"
                        data-testid="organization-logo"
                    />
                </div>
                {feedbackPopup && (
                    <FeedbackPopupModal
                        visible={feedbackPopup}
                        pages={transformMenu(userMenu)}
                        onHide={() => setFeedbackPopup(false)}
                    />
                )}
                <Sidebar
                    visible={visible}
                    onHide={() => setVisible(false)}
                    data-testid="sidebar"
                    pt={{
                        root: { className: 'w-3/4 md:w-2/4 relative' },
                        header: {
                            className: 'w-10 p-2 absolute right-0'
                        },
                        icons: {
                            className: 'border-0'
                        },
                        closeButtonIcon: {
                            className: 'transition-none'
                        },
                        content: {
                            className: 'pt-4 p-4'
                        }
                    }}>
                    <ul>
                        {userMenu.map((item) =>
                            !item.submenu ? (
                                <Link
                                    data-testid={'p-' + item.menu}
                                    to={item.path}
                                    key={`p-${item.id}`}
                                    className={`cursor-pointer flex p-2 font-medium ${isActive(item.path) ? 'border-l-4 border-l-secondary text-secondary' : ''}`}>
                                    <li className="text-lg">{t(`${item.menu}`)}</li>
                                </Link>
                            ) : (
                                <li key={item.id}>
                                    <li
                                        data-testid={'p-' + item.menu}
                                        className={`cursor-pointer p-2 pb-1 font-medium text-lg ${isSubmenuActive(item.submenu) ? 'border-l-4 border-l-secondary text-secondary' : ''}`}
                                        key={`p-${item.id}`}>
                                        {t(`${item.menu}`)}
                                    </li>
                                    {item.submenu.map((submenuItem) => (
                                        <Link
                                            data-testid={'s-' + item.menu}
                                            to={submenuItem.path}
                                            key={`s-${submenuItem.id}`}
                                            className={`${isActive(submenuItem.path) ? 'text-secondary' : ''}`}>
                                            <li className="ml-5 p-2 font-medium text-sm">
                                                {t(`${submenuItem.title}`)}
                                            </li>
                                        </Link>
                                    ))}
                                </li>
                            )
                        )}
                    </ul>
                </Sidebar>
                <div className="ml-12 h-full hidden lg:block">
                    <ul className="flex items-center gap-8 font-medium h-full">
                        {userMenu?.slice(0, 6).map((item) =>
                            item.submenu ? (
                                <li key={item.id} className="h-full">
                                    <div className="dropdown h-full flex items-center">
                                        <div
                                            tabIndex={0}
                                            role="button"
                                            className={`text-lg flex items-center relative h-full hover:text-secondary after:block after:content-[''] after:absolute after:h-[0.3rem] after:mt-[3.5rem] after:bg-secondary after:rounded-ss-[10rem] after:rounded-se-[10rem] overflow-hidden after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center ${isSubmenuActive(item.submenu) ? "text-secondary after:block after:content-[''] after:absolute after:h-[0.35rem] after:mt-[3.7rem] after:bg-secondary after:rounded-ss-[3rem] after:rounded-se-[3rem] overflow-hidden after:w-full after:scale-x-100 after:origin-center" : ''}`}>
                                            {t(`${item.menu}`)}
                                            <i className="pi pi-chevron-down text-lg pt-1 ml-2" />
                                        </div>
                                        <ul
                                            tabIndex={0}
                                            className="dropdown-content z-50 bg-white menu p-1 shadow-ms rounded-md min-w-52 border-neutral border-2 mt-[2.05rem] top-7 cursor-pointer">
                                            {item.submenu.map((submenuItem) => (
                                                <>
                                                    {submenuItem.permission.find((p) =>
                                                        AuthStore.hasPermission(p)
                                                    ) && (
                                                        <Link
                                                            data-testid={'s-' + submenuItem.title}
                                                            to={submenuItem.path}
                                                            key={`s-${submenuItem.id}`}
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    SetMenuIcoHighlightIcon(false);
                                                                }
                                                            }}
                                                            onClick={() =>
                                                                SetMenuIcoHighlightIcon(false)
                                                            }
                                                            className={`hover:bg-primary-50 ${isActive(submenuItem.path) ? 'text-secondary' : ''}`}>
                                                            <li className="p-3 test-lg">
                                                                {t(`${submenuItem.title}`)}
                                                            </li>
                                                        </Link>
                                                    )}
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                </li>
                            ) : (
                                <Link
                                    to={item.path}
                                    key={item.id}
                                    data-testid={'p-' + item.menu}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            SetMenuIcoHighlightIcon(false);
                                        }
                                    }}
                                    onClick={() => SetMenuIcoHighlightIcon(false)}
                                    className={`cursor-pointer h-full flex items-center relative hover:text-secondary after:block after:content-[''] after:absolute after:h-[0.315rem] after:mt-[3.7rem] after:bg-secondary after:rounded-ss-[3rem] after:rounded-se-[3rem] overflow-hidden after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center ${isActive(item.path) ? "text-secondary after:block after:content-[''] after:absolute after:h-[0.35rem] after:mt-[3.7rem] after:bg-secondary after:rounded-ss-[3rem] after:rounded-se-[3rem] overflow-hidden after:w-full after:scale-x-100 after:origin-center" : ''}`}>
                                    <li className="text-lg">{t(`${item.menu}`)}</li>
                                </Link>
                            )
                        )}

                        <>
                            {!headerMenuItemsLength &&
                                userMenu?.slice(5).map(() => setHeaderMenuItemsLength(true))}
                        </>
                        <>
                            {headerMenuItemsLength && (
                                <div className="dropdown h-full">
                                    <div
                                        data-testid="submenu-dropdown"
                                        tabIndex={0}
                                        className="btn btn-ghost border-0 h-full"
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                toggleDropdown(true);
                                            }
                                        }}
                                        onClick={() => toggleDropdown(true)}>
                                        <img
                                            src={menuIcon}
                                            className="w-6"
                                            alt=""
                                            style={
                                                menuIconHighlightIcon ? menuIconStyle : undefined
                                            }
                                        />
                                    </div>
                                    {isMenuDropdownOpen && (
                                        <ul
                                            data-testid="submenu-dropdown-content"
                                            tabIndex={0}
                                            className="p-1 shadow-md menu dropdown-content z-[50] bg-white rounded-md min-w-60 border-2 border-neutral cursor-pointer"
                                            id="remainingHeaderMenuItem">
                                            {userMenu
                                                ?.filter((item) => item.id !== 19) // Filter out item with id 19 - 'PV Settings'
                                                .slice(6)
                                                .map((item) => (
                                                    <Link
                                                        data-testid={'s-' + item.menu}
                                                        to={item.path}
                                                        key={item.id}
                                                        className={`hover:bg-primary-50 ${
                                                            isActive(item.path)
                                                                ? 'text-secondary'
                                                                : ''
                                                        }`}>
                                                        <li
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    toggleDropdown(false);
                                                                    SetMenuIcoHighlightIcon(true);
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                toggleDropdown(false);
                                                                SetMenuIcoHighlightIcon(true);
                                                            }}
                                                            className="p-3 text-lg">
                                                            {t(`${item.menu}`)}
                                                        </li>
                                                    </Link>
                                                ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                        </>
                    </ul>
                </div>

                <div className="ml-auto h-full flex items-center">
                    {/* <div className="indicator mx-2 h-full flex items-center">
                        <span className="indicator-item badge badge-secondary badge-xs  top-[1.40rem] right-1 bg-secondary border-white"></span>
                        <i className="pi pi-bell text-base md:text-xl cursor-pointer"></i>
                    </div> */}
                    {isUATEnvIndicate && (
                        <div className="border-2 border-secondary p-2 mx-3 rounded-md">
                            <p className="text-secondary font-semibold text-xl">Sandbox</p>
                        </div>
                    )}
                    <button
                        className="mx-2 h-full flex items-center"
                        data-testid="feedback-button"
                        onClick={() => {
                            setFeedbackPopup(true);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                setFeedbackPopup(true);
                            }
                        }}>
                        <img
                            alt="Feedback"
                            src="/images/svgs/feedback-icon.svg"
                            className="h-7 w-7 text-base md:text-xl cursor-pointer"
                        />
                    </button>
                    {AuthStore.hasPermission(Permissions.VIEW_IP_VAULT_SETTINGS) && (
                        <div className="hidden md:block">
                            <Tooltip
                                target=".pv-setting-button"
                                mouseTrack={false}
                                position="bottom"
                                content={t('MENU.PV_SETTING')}
                                pt={{
                                    root: { className: 'shadow-none ' },
                                    text: { className: 'text-xs' }
                                }}
                            />
                            <button
                                className="pv-setting-button mx-2 h-full flex items-center"
                                data-testid="pv-setting-button"
                                onClick={() => {
                                    handlePvSetting();
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handlePvSetting();
                                    }
                                }}>
                                <img
                                    alt="ipv-setting"
                                    src="/images/svgs/i-settings-icon.svg"
                                    className="h-7 w-7 text-base md:text-xl cursor-pointer"
                                />
                            </button>
                        </div>
                    )}
                    <div tabIndex={0} role="button" className="dropdown dropdown-end h-full">
                        <div
                            tabIndex={0}
                            className="btn btn-ghost border-0 h-full pl-1 md:pl-3 pr-1 flex items-center"
                            data-testid="profileDropdownToggle"
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    toggleProfileDropdown(true);
                                }
                            }}
                            onClick={() => toggleProfileDropdown(true)}>
                            <div className="avatar placeholder">
                                <div className="bg-[url('/public/images/svgs/profile-bg.png')] bg-cover text-white rounded-full w-8 lg:w-10 ">
                                    <span>{getAvatarName(userInfo?.userInfo.userFullName)}</span>
                                </div>
                            </div>
                            <div className="flex flex-col items-start gap-2">
                                <p className="font-medium text-sm truncate max-w-28 md:max-w-max">
                                    {userInfo?.userInfo.userFullName}
                                </p>
                                <span className="text-xs font-medium leading-3 text-primary">
                                    {userInfo?.userInfo.role}
                                </span>
                            </div>
                            <span>
                                <i className="pi pi-chevron-down text-xs pb-3 mt-1" />
                            </span>
                        </div>
                        {isProfileDropdownOpen && (
                            <ul
                                data-testid="profile-dropdown-content"
                                tabIndex={0}
                                className="p-1 shadow-md menu dropdown-content z-[50] bg-white rounded-md w-52 border-2 border-neutral border-t-primary border-t-[0.2rem]">
                                {AuthStore.hasPermission(Permissions.UPDATE_USER_PROFILE) && (
                                    <li
                                        data-testid="PROFILE.ACCOUNT_SETTINGS"
                                        className="py-1 px-0 lg:hover:bg-primary-50 cursor-pointer"
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                handleAccountSettings();
                                                toggleProfileDropdown(false);
                                            }
                                        }}
                                        onClick={() => {
                                            handleAccountSettings();
                                            toggleProfileDropdown(false);
                                        }}>
                                        <div className="font-medium flex items-center">
                                            <img
                                                className="w-4"
                                                src={accountSettings}
                                                alt="account settings"
                                            />
                                            <span>{t('PROFILE.ACCOUNT_SETTINGS')}</span>
                                        </div>
                                    </li>
                                )}
                                <li
                                    data-testid="PROFILE.LOGOUT"
                                    className="py-1 px-0 lg:hover:bg-primary-50 cursor-pointer"
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            handleLogout();
                                            toggleProfileDropdown(false);
                                        }
                                    }}
                                    onClick={() => {
                                        handleLogout();
                                        toggleProfileDropdown(false);
                                    }}>
                                    <div
                                        data-testid="logout"
                                        className="font-medium text-secondary flex items-center">
                                        <i className="pi pi-sign-out w-4" />
                                        <span className="">{t('PROFILE.LOGOUT')}</span>
                                    </div>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderMenu;
